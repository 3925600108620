<template>
  <div class="ccwebsocket_bg">
    <div class="gonggao" v-show="changeGongGaoStr && this.showAllGongGaoRet == false" @click="showAllGonggaoAction">
      <img
          src="https://html-istock.oss-cn-beijing.aliyuncs.com/live_tips_ic_notice.png?versionId=CAEQIxiBgMCW3smxlBgiIDZlM2RlMTczNDViNTQ4Y2NhMzNlNzQ2MTVkNTFjMjY5"
          class="gonggao_head">
      {{ changeGongGaoStr }}
      <img
          src="https://html-istock.oss-cn-beijing.aliyuncs.com/orange_down.png?versionId=CAEQIxiBgMC6m9exlBgiIDg5YTEwMTZlNzZlYzRjYjc4MTllMTA0NTdjZDM4NjQy"
          class="gonggao_foot">
    </div>
    <div class="gonggao_all" v-show="changeGongGaoStr && this.showAllGongGaoRet" @click="showAllGonggaoAction">
      <img
          src="https://html-istock.oss-cn-beijing.aliyuncs.com/live_tips_ic_notice.png?versionId=CAEQIxiBgMCW3smxlBgiIDZlM2RlMTczNDViNTQ4Y2NhMzNlNzQ2MTVkNTFjMjY5"
          class="gonggao_head">
      {{ changeGongGaoStr }}
      <img
          src="https://html-istock.oss-cn-beijing.aliyuncs.com/orange_up.png?versionId=CAEQIxiBgMCfo8CylBgiIGQ5NDFkOTY1YTZkYTQ5OGQ5ZmFkMDkzMzc5ZWU1OWUy"
          class="gonggao_foot">
    </div>
    <div class="one_chat_message" v-for="(item, index) in chats" :key="index">
      <div class="system_message" v-show="item.type == 2">{{ item.text }}</div>
      <div class="people_message" v-show="item.type == 1"><span>{{ item.name }}</span>: {{ item.text }}</div>
    </div>
    <div class="bottom_space"></div>
  </div>
</template>

<script>
import TencentCloudChat from "@tencentcloud/chat";

export default {
  name: 'ccsocket',
  components: {},
  props: {
    imRoomId: {
      default: '-1',
    },
    imname: "",
    account: "",
    deviceId: "",
    path: {
      default: 'wss://im.tinggupiao.com.cn/websocket'
    },
    canSpeak: {
      default: true
    },
    gonggaoStr: '',
    isPayLive: {
      default: false
    },
    offSetTop: {
      default: 0
    }
    , isDev: {
      default: false
    }
    , imUserId: {
      default: ''
    }
    , imUserSig: {
      default: ''
    },
    imMethod: {
      default: "erdianling"
    }
  },
  watch: {
    chats: {
      handler(newChats) {
        console.log(newChats)
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      deep: true
    },
  },
  data() {
    return {
      deviceType: 'ios',
      connectAgainTime: 0,
      loginSuccessMessage: '',
      chats: [],
      nextFreeRoomDic: {},
      nextPayRoomDic: {},
      showAllGongGaoRet: false,

      imRoomIdStr: '',
      imAccountStr: '',
      imNameStr: '',
      imDeviceIdStr: '',
      changeGongGaoStr: '',
      changeCanSpeak: '',
      canAutoScrollRet: false,
      sapcePeopleMessage: 'ccc10086',
      isChangeRoomRet: false,
      msgIdArray: [],
      accessAcountArray: [],
      wsInstance: null,
    }
  },
  methods: {
    initWebSocket() {
      if (this.imMethod === "tencent") {
        //腾讯IM创建链接
        let options = {
          SDKAppID: 1600054913 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
        };
        this.chat = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示
        this.chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用

        let onMessageReceived = (event) => {
          const messageList = event.data;
          messageList.forEach((message) => {
            if (message.type === TencentCloudChat.TYPES.MSG_TEXT) {
              console.log("接收消息，接收人信息:" + message.ID + " 消息内容：" + message.payload.text);
              let body = message.payload.text;
              this.getMessage({data: body});
            }
          });
        }
        this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);


        let promise = this.chat.login({
          userID: this.imUserId.toString(),
          userSig: this.imUserSig
        });

        promise.then((imResponse) => {
          console.warn('login:'); // 登录失败的相关信息
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log("login success:", imResponse.data.errorInfo);
          }
          this.chat.joinGroup({groupID: this.imRoomIdStr.toString()});
        }).catch((imError) => {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      } else {
        console.log("initWebSocket")
        const urlcreator = async (isReconnect) => {
          // 模拟返回 URL 和超时时间
          return {
            url: 'wss://im.tinggupiao.com.cn/websocket', // 替换为实际 WebSocket 地址
            timeout: 10000, // 心跳超时时间
          };
        };

        const handler = {
          onopen: (event, ws) => {
            console.log('WebSocket Opened:', event);
            this.open();
          },
          onmessage: (event, ws) => {
            // 获取当前时间
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const currentTime = `${hours}:${minutes}:${seconds}`;

            // 输出 WebSocket 消息和当前时间
            console.log(`WebSocket Message at ${currentTime}:`, event.data);
            this.getMessage(event);
          },
          onclose: (event, ws) => {
            console.log('WebSocket Closed:', event);
          },
          onerror: (event, ws) => {
            console.error('WebSocket Error:', event);
          },
          ping: (ws) => {
            console.log('index.vue Sending Ping');
            this.keepAlive();
          }
        }
        this.wsInstance = this.$tioWs(urlcreator, {}, handler);
        this.wsInstance.connect();
      }
    },

    // 常规操作
    open: function () {
      console.log("websocket open");
      this.saveDevOpenMessage();
      this.imLogin();
    },
    close: function () {
    },
    keepAlive: function () {
      // 判断当前webscokt状态
      // 调用发送方法
      const actions = {
        fromClientType: this.deviceType,
        fromAccount: this.imAccountStr,
        fromDeviceId: this.imDeviceIdStr,
        eventType: '0',
        to: '0'
      }
      this.send(JSON.stringify(actions));
    },
    //发


    send(params) {
      if(this.wsInstance){
        this.wsInstance.send(params)
      }else if(this.chat) {
        // 发送文本消息，Web 端与小程序端相同
        // 1. 创建消息实例，接口返回的实例可以上屏
        const parsedObject = JSON.parse(params);
        let eventType = parsedObject.eventType;
        let to = parsedObject.to;

        parsedObject.msgId = this.generateUUID();
        let msgText = JSON.stringify(parsedObject);
        //发送普通消息
        if (eventType == "6" || eventType == 6) {
          this.chat.joinGroup({groupID: to.toString()});

          let message = this.chat.createTextMessage({
            to: to.toString(),
            conversationType: TencentCloudChat.TYPES.CONV_GROUP,
            // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
            // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
            payload: {
              text: msgText
            },
            // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
            needReadReceipt: false
            // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
            // cloudCustomData: 'your cloud custom data'
          });
          // 2. 发送消息
          let promise = this.chat.sendMessage(message);
          promise.then(function (imResponse) {
            // 发送成功
            console.log(imResponse);
          }).catch(function (imError) {
            // 发送失败
            console.warn('sendMessage error:', imError);
          });
        }
      }
    },
    //收
    getMessage: function (msg) {
      let data = JSON.parse(msg.data)
      switch (parseInt(data.eventType)) {
        case 200: {
          this.accessRoom();
          break;
        }
        case 204: {
          // 打招呼
          this.receiveHelloMessage(data);
          console.log(data);
          break;
        }
        case 6: {
          //收到用户消息
          this.receivePeopleMessage(data);
          console.log(data);
          break;
        }
        case 102: {
          //消息 msgId过滤
          this.changeOneMessageMsgId(data);
          console.log(data);
          break;
        }
        case 204: {
          this.accessAcountArray.push(data.to);
          break;
        }
        case 205: {
          //顶号
          this.otherPlaceLoginAction();
          console.log(data);
          break;
        }
        case 206: {
          //切流
          this.changeRoomAction();
          break;
        }
        case 207: {
          //撤回消息
          this.deleteOneMessage(data);
          console.log(data);
          break;
        }
        case 208: {
          //公告
          this.changeGongGao(data);
          console.log(data);
          break;
        }
        case 209: {
          //禁言
          this.changeCanSpeak = false;
          let dataContent = JSON.parse(data.dataContent)
          this.$emit("cantSpeakAction", dataContent.isMute);
          break;
        }
        case 210: {
          // 人数
          this.changeRoomCountAction(data);
          console.log(data);
          break;
        }
        case 211: {
          //下一场付费直播间信息
          console.log(data);
          this.nextPayRoomAction(data);
          break;
        }
        case 212: {
          // 下一场免费直播间信息
          console.log(data);
          this.nextFreeRoomAction(data);
          break;
        }
        case 213: {
          console.log(data);
          // 暂无正在直播直播间
          this.nextNoRoomAction();
          break;
        }
        default: {
          this.$emit("otherMessageReceive", data);
          break;
        }

      }
    },
    // 打招呼
    receiveHelloMessage(data) {
      if (this.loginSuccessMessage != data.dataContent) {
        this.loginSuccessMessage = data.dataContent;
        this.chats.unshift({
          text: data.dataContent,
          name: '',
          type: '2'
        });
      }
      if (this.sapcePeopleMessage != "ccc10086") {
        this.realySendOneMessage();
      }
    },
    //收到用户消息
    receivePeopleMessage(data) {
      const uid = data.fromAccount
      if (this.ifNullStr(data.fromNick)) {
        data.fromNick = "用户";
      }
      var contain = this.msgIdArray.indexOf(data.msgId);
      //console.log(contain);
      if (contain != -1 && data.msgId != this.imAccountStr) {
        return;
      }
      this.msgIdArray.push(data.msgId);
      if (data.fromNick != this.imNameStr) {
        var oneMessage = {
          name: data.fromNick,
          text: data.dataContent,
          msgId: data.msgId,
          uid: uid,
          type: '1',
          avatar: ""
        };
        this.chats.push(oneMessage);
      }
    },
    //消息 msgId过滤
    changeOneMessageMsgId(data) {
      for (var i = 0; i < this.chats.length; i++) {
        var item = this.chats[i];
        if (item.msgId == this.imAccountStr) {
          item.msgId = data.msgId;
          //console.log(item);
          break;
        }
      }
    },
    // 撤回
    deleteOneMessage(data) {
      const msgId = JSON.parse(data.dataContent)
      this.chats.forEach((val) => {
        if (val.type === '1' && val.msgId === msgId.msgId) {
          val.type = ''
        }
      })
    },
    //公告
    changeGongGao(data) {
      const dataContent = JSON.parse(data.dataContent)
      this.changeGongGaoStr = dataContent.annTitle + ": " + dataContent.annContent;
    },
    currentIfBottom() {
      console.log("+++++");
      var msg = document.getElementsByClassName('ccwebsocket_bg')[0] // 获取对象
      if (parseInt(this.offSetTop) + msg.clientHeight > window.innerHeight) {
        var leftValue = window.innerHeight + window.scrollY;
        var rightValue = msg.clientHeight + parseInt(this.offSetTop);
        console.log(leftValue);
        console.log(rightValue);
        if (rightValue - leftValue < 60) {
          console.log("到底");
          return true
        } else {
          console.log("no到底");
          return false
        }
      } else {
        console.log("到底");
        return true
      }
    },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        const lastMessage = chatContainer.lastElementChild;
        if (lastMessage) {
          lastMessage.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
      }
    },
    //登录
    imLogin() {
      const dataContent = {
        account: this.imAccountStr,
        token: this.imAccountStr,
        ext: this.deviceType
      }
      const actions = {
        dataContent: JSON.stringify(dataContent),
        eventType: '100'
      }
      this.send(JSON.stringify(actions))
      console.log('ccwebsocket login:' + JSON.stringify(actions));
    },

    // 进入房间
    accessRoom() {
      const actions = {
        fromClientType: this.deviceType,
        fromAccount: this.imAccountStr,
        fromDeviceId: this.imDeviceIdStr,
        fromNick: this.imNameStr,
        eventType: '4',
        to: this.imRoomIdStr
      }
      if (this.accessAcountArray.indexOf(this.imAccountStr) >= 0) {
        console.log("ccwebsocket accessRoom:");
        console.log(this.accessAcountArray);
      } else {
        this.send(JSON.stringify(actions))
        console.log('ccwebsocket accessRoom:' + JSON.stringify(actions));
      }

    },
    leaveRoomAction() {
      const actions = {
        fromClientType: this.deviceType,
        fromAccount: this.imAccountStr,
        fromDeviceId: this.imDeviceIdStr,
        eventType: '5',
        to: this.imRoomIdStr
      }
      this.send(JSON.stringify(actions))
    },
    // UUID
    generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    ifNullStr(str) {
      if (str == undefined || !str || str.length == 0) {
        return true
      } else {
        return false
      }
    },
    showAllGonggaoAction() {
      this.showAllGongGaoRet = !this.showAllGongGaoRet
    },
    //默认数据 和更新数据
    setNotLoginData() {
      if (this.ifNullStr(this.imRoomId)) {
        this.imRoomIdStr = "1";
      } else {
        this.imRoomIdStr = this.imRoomId;
      }
      if (this.ifNullStr(this.account)) {
        if (this.ifNullStr(this.imAccountStr)) {
          this.imAccountStr = this.generateUUID();
        }
      } else {
        this.imAccountStr = this.account;
      }
      if (this.ifNullStr(this.deviceId)) {
        if (this.ifNullStr(this.imDeviceIdStr)) {
          this.imDeviceIdStr = this.generateUUID();
        }
      } else {
        this.imDeviceIdStr = this.deviceId;
      }
      if (this.ifNullStr(this.imname)) {
        if (this.ifNullStr(this.imNameStr)) {
          this.imNameStr = "客户" + this.imAccountStr.substring(0, 6);
        }
      } else {
        this.imNameStr = this.imname;
      }
      if (this.ifNullStr(this.gonggaoStr)) {
        this.changeGongGaoStr = ""
      } else {
        this.changeGongGaoStr = this.gonggaoStr;
      }
      this.changeCanSpeak = this.canSpeak;
      const baseU = navigator.userAgent
      var isIos = !!baseU.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (!isIos) {
        this.deviceType = "andorid";
      }
      console.log(this.imRoomIdStr + "," + this.imAccountStr + "," + this.imDeviceIdStr + "," + this.imNameStr + "," + this.deviceType + this.isPayLive + this.changeGongGaoStr)
    },
    //外部引用
    inserHistoryData(dataArray) {
      for (var i = 0; i < dataArray.length; i++) {
        var data = dataArray[i];
        var oneMessage = {
          name: data.fromNick,
          text: data.body,
          msgId: data.msgidClient,
          uid: data.fromAccount,
          type: '1',
          avatar: ""
        };
        this.chats.push(oneMessage);
      }
    },
    changeCurrentRoomAction(imRoomId) {
      this.isChangeRoomRet = true;
      this.imRoomIdStr = imRoomId;
      this.accessRoom();

    },
    sendOneMessageAction(message) {
      this.sapcePeopleMessage = message;
      this.realySendOneMessage();
      this.scrollToBottom();
    },
    realySendOneMessage() {
      var message = this.sapcePeopleMessage;
      //发送数据
      const actions = {
        to: this.imRoomIdStr,
        dataType: '1',
        eventType: '6',
        fp: '',
        dataContent: message,
        QoS: '1',
        fromClientType: this.deviceType,
        fromAccount: this.imAccountStr,
        fromNick: this.imNameStr,
        fromDeviceId: this.imDeviceIdStr,
      }
      this.send(JSON.stringify(actions));
      var oneMessage = {
        name: this.imNameStr,
        text: message,
        msgId: this.imAccountStr,
        uid: this.imAccountStr,
        type: '1'
      }
      this.chats.push(oneMessage);
      this.sapcePeopleMessage = "ccc10086";
    },
    //暴露方法 - 顶号
    otherPlaceLoginAction() {
      if (this.isChangeRoomRet == false) {
        this.$emit("otherPlaceLoginAction");
      } else {
        this.isChangeRoomRet = false;
      }
    },
    //暴露方法 - 切流
    changeMediaAction() {
      this.$emit("changeMediaAction");
    },
    //暴露方法 - 人数
    changeRoomCountAction(data) {
      const online = JSON.parse(data.dataContent)
      this.$emit("changeRoomCountAction", online.onlineCount);
    },
    //暴露方法 - 下一场 付费
    nextPayRoomAction(data) {
      var roomDic = JSON.parse(data.dataContent);
      this.$emit("nextPayRoomAction", roomDic);
    },
    //暴露方法 - 下一场 免费
    nextFreeRoomAction(data) {
      var roomDic = JSON.parse(data.dataContent);
      this.$emit("nextFreeRoomAction", roomDic);
    },
    //暴露方法 - 无下一场
    nextNoRoomAction() {
      this.$emit("nextNoRoomAction");
    },
    saveDevCloseMessage() {
      if (this.isDev)
        this.chats.push({
          text: "socket关闭了",
          name: '+++++++++++++++++++++++++++++',
          type: '1'
        });
    },
    saveDevOpenMessage() {
      if (this.isDev) {
        this.chats.push({
          text: "socket开启了",
          name: '+++++++++++++++++++++++++++++',
          type: '1'
        });
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.userScrollAction, true);
    setTimeout(() => {
      this.setNotLoginData();
      console.log("imRoomIdStr", this.imRoomIdStr);

      this.initWebSocket()
    }, 2000);

  },
  created() {
  },
  destroyed() {
    window.removeEventListener('scroll', this.userScrollAction, true);
    if(this.chat){
      this.chat.logout();
    }
  }
}
</script>

<style scoped>
.ccwebsocket_bg {
  position: relative;
  background-color: #f8f8f8;
}

.ccwebsocket_bg .have_new_voice {
  position: fixed;
  bottom: 54px;
  left: 50%;
  transform: translateX(-50%);

  width: 112px;
  height: 38px;
  background: #FFF4EB;
  border-radius: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF7200;
  line-height: 38px;
  text-align: center;

}

.ccwebsocket_bg .have_new_voice img {
  width: 10px;
  vertical-align: middle;
  margin-left: 5px;
}

.ccwebsocket_bg .system_message {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  word-break: break-all;
}

.ccwebsocket_bg .one_chat_message {
  padding: 0 16px;
}

.ccwebsocket_bg .people_message {
  padding: 8px 0;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 21px;
  word-break: break-all;
}

.ccwebsocket_bg .people_message span {
  padding: 8px 0;
  color: #999999;
}

.ccwebsocket_bg .gonggao {
  position: relative;
  padding-right: 50px;
  padding-left: 40px;
  min-height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF7200;
  background-color: #FFF4EB;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
  margin-bottom: 20px;
}

.ccwebsocket_bg .gonggao .gonggao_head {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
}

.ccwebsocket_bg .gonggao .gonggao_foot {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  vertical-align: middle;
}

.ccwebsocket_bg .gonggao_all {
  position: relative;
  padding-top: 5px;
  padding-right: 50px;
  padding-left: 40px;
  min-height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF7200;
  background-color: #FFF4EB;
  line-height: 30px;
  margin-bottom: 0;
}

.ccwebsocket_bg .gonggao_all .gonggao_head {
  position: absolute;
  left: 16px;
  top: 20px;
  transform: translateY(-50%);
  width: 14px;
}

.ccwebsocket_bg .gonggao_all .gonggao_foot {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
}

.bottom_space {
  height: 54px;
  background-color: #f8f8f8;
}
</style>
